<template>
  <div>
    <q-form ref="editForm">
      <!-- 수급업체 평가 기본정보 -->
      <c-card title="수급업체 평가 기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- [S]결재관련 버튼 -->
            <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
            <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
            <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
            <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
            <c-appr-btn
              ref="appr-btn"
              name="vendor-assess-appr-btn"
              :editable="editable && isOld && !disabled"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveVendorAssess('appr')"
              @callbackApprAction="approvalCallback"
              @requestAfterAction="getDetail"
            />
            <c-btn 
              v-if="editable && isOld && !disabled" 
              label="삭제" 
              icon="remove" 
              @btnClicked="deleteData"/>
            <!-- [E]]결재관련 버튼 -->
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="evaluationData"
              :mappingType="saveType"
              label="저장"
              icon="save"
              @beforeAction="saveVendorAssess('save')"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-description">
          <q-chip v-if="evaluationData.evaluationGradeName" :color="evaluationGradeInfo.attrVal3" dense style="vertical-align: baseline;" outline square>
              {{$language('평가등급')}} : {{$language(evaluationData.evaluationGradeName)}}
          </q-chip>
        </template>
        <template slot="card-detail">
          <div class="col-2">
            <c-plant
              :required="true"
              :disabled="disabled||isOld"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="evaluationData.plantCd"
              @datachange="getResultItem" />
          </div>
          <div class="col-2">
            <!-- 평가업체 -->
            <c-vendor
              :disabled="disabled"
              :editable="editable"
              :required="true"
              label="평가업체"
              name="vendorCd"
              v-model="evaluationData.vendorCd">
            </c-vendor>
          </div>
          <div class="col-2">
            <!-- 평가구분 -->
            <c-select
              :required="true" 
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="VENDOR_EVALUATION_KIND_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorEvaluationKindCd"
              label="평가구분"
              v-model="evaluationData.vendorEvaluationKindCd"
            ></c-select>
          </div>
          <div class="col-2">
            <!-- 평가기간 -->
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :range="true"
              label="평가기간"
              name="period"
              v-model="evaluationData.evaluationPeriod">
            </c-datepicker>
          </div>
          <div class="col-2">
            <!-- 평가년도 -->
            <c-datepicker
              :required="true" 
              :disabled="disabled"
              :editable="editable"
              type="year"
              label="평가년도"
              name="evaluationYear"
              v-model="evaluationData.evaluationYear"
            />
          </div>
          <div class="col-2">
            <!-- 평가자 -->
            <c-field
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :data="evaluationData"
              :plantCd="evaluationData.plantCd"
              type="dept_user"
              label="평가자"
              name="assessUserId"
              v-model="evaluationData.assessUserId" />
          </div>
          <div class="col-4">
            <!-- 관련공사 -->
            <c-construnction
              :disabled="disabled"
              :editable="editable"
              name="sopConstructionId"
              v-model="evaluationData.sopConstructionId">
            </c-construnction>
          </div>
          <div class="col-6">
            <!-- 평가결과 종합 -->
            <c-text 
              :disabled="disabled"
              :editable="editable"
              label="평가결과 종합"
              name="remarks"
              v-model="evaluationData.remarks">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <!-- 평가 항목 합계 -->
    <c-table
      ref="table"
      title="평가 항목 합계"
      :editable="editable&&!disabled"
      :columns="gridSubTotal.columns"
      :data="evaluationData.itemResultSubTotals"
      :gridHeightAuto="true"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
      :isTop="false"
      :hideBottom="true"
    >
      <template slot="description-front-tr" slot-scope="props">
        <template v-if="props.rowIndex === 0">
          <q-tr :props="props" :key="`e_front_${props.rowIndex}`" class="q-virtual-scroll--with-prev bg-blue-5 vendor-assess-item-sub-total-tr">
            <q-td>
              {{$language('합계')}}
            </q-td>
            <q-td class="text-align-right">
              {{ evaluationData.totalScore }}
            </q-td>
            <q-td class="text-align-right">
              {{ evaluationData.score }}
            </q-td>
          </q-tr>
        </template>
      </template>
    </c-table>
    <c-table
      ref="table"
      title="평가항목 목록"
      :editable="editable&&!disabled"
      :columns="grid.columns"
      :data="gridItemResults"
      :gridHeight="gridHeight"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
      :customTrClass="customTrClass"
    >
      <template slot="suffixTitle">
        <font style="font-size:0.8em;font-weight:300;">
          <q-badge 
            v-for="(score, idx) in scoreItems"
            :key="idx"
            :class="score.attrVal1"
            class="q-mr-sm"
            :label="$language(score.codeName)" />
        </font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="!props.row.subtotal">
          <c-number-column
            :editable="editable"
            :disabled="disabled || !editable"
            :col="col"
            :props="props"
            :colorClass="checkColorClass(props.row)"
            v-model="props.row[col.name]"
            @datachange="datachange(props, col)"
          />
        </template>
        <template v-else>
          {{props.row[col.name]}}
        </template>
      </template>
    </c-table>
  </div>
</template>
<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'vendor-assess-info',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmVendorEvaluationId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      evaluationData: {
        mdmVendorEvaluationId: '',  // 업체평가 일련번호
        vendorCd: null,  // 업체코드
        plantCd: null,  // 사업장코드
        vendorEvaluationKindCd: null,  // 수급업체 평가구분
        evaluationStartDate: '',  // 평가시작일
        evaluationEndDate: '',  // 평가종료일
        evaluationPeriod: [],
        evaluationYear: '',  // 평가 년도
        score: null,  // 득점
        totalScore: null,  // 총점
        evaluationGradeCd: null,  // 평가등급
        evaluationGradeName: '',
        remarks: '',  // 비고
        assessUserId: '',
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        sopConstructionId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        itemResults: [],
        itemResultSubTotals: [],
      },
      gradeItems: [],
      scoreItems: [],
      grid: {
        columns: [
          {
            name: "itemNm",
            field: "itemNm",
            label: "평가항목",
            align: "left",
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:80px', 
            align: "right",
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "득점",
            style: 'width:80px', 
            type: "custom",
            align: "center",
            setHeader: true,
            sortable: false,
          },
          {
            name: "remarks",
            field: "remarks",
            label: "비고",
            type: "text",
            align: "left",
            style: 'width:300px', 
            sortable: false,
          },
        ],
        data: [],
      },
      gridSubTotal: {
        columns: [
          {
            name: "vendorEvaluationTypeName",
            field: "vendorEvaluationTypeName",
            label: "구분",
            align: "left",
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:80px', 
            align: "right",
            type: 'cost',
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "득점",
            style: 'width:80px', 
            align: "right",
            type: 'cost',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      isApproval: false,
      saveType: 'POST',
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      itemListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return (this.contentHeight - 450) + 'px'
    },
    isOld() {
      return Boolean(this.popupParam.mdmVendorEvaluationId)
    },
    disabled() {
      return this.evaluationData.evaluationCompleteFlag === 'Y' 
        // 결재중인 경우 비활성화
        || this.evaluationData.approvalStatusCd === 'ASC0000001'
    },
    evaluationGradeInfo() {
      let info = {
        code: '',
        codeName: '',
        attrVal1: '',
        attrVal2: '',
        attrVal3: '',
        description: '',
      };
      if (this.evaluationData.evaluationGradeCd 
        && this.gradeItems && this.gradeItems.length > 0) {
          info = this.$_.find(this.gradeItems, { code: this.evaluationData.evaluationGradeCd })
      }
      return info;
    },
    gridItemResults() {
      let _itemResults = [];
      if (this.evaluationData.itemResults && this.evaluationData.itemResults.length > 0) {
        let subTotals = this.$_.map(this.evaluationData.itemResultSubTotals, item => {
          return this.$_.extend(item, {
            itemNm: item.vendorEvaluationTypeName,
            disable: true,
            subtotal: true,
          })
        })
        this.$_.forEach(subTotals, item => {
          _itemResults.push(item)
          _itemResults = _itemResults.concat(this.$_.filter(this.evaluationData.itemResults, { vendorEvaluationTypeCd: item.vendorEvaluationTypeCd }));
        })
      }
      return _itemResults
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.evaluationData.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.evaluationData.approvalStatusCd, 
        apprEditable: this.evaluationData.evaluationCompleteFlag === 'N', // 결재버튼 활성화 기준
        param: this.evaluationData, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000002', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          mdmVendorEvaluationId: this.evaluationData.mdmVendorEvaluationId,
          isApprContent: true
        },
        approvalRequestName: '수급업체 평가 ['+this.evaluationData.vendorName+']', // 결재요청명 (문서 title)
        approvalConnId: this.evaluationData.mdmVendorEvaluationId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.cim.vendor.evaluation.result.get.url;
      this.itemListUrl = selectConfig.mdm.cim.vendor.evaluation.result.item.url;
      this.saveUrl = transactionConfig.mdm.cim.vendor.result.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.result.delete.url;
      // code setting
      this.$comm.getComboItems('EVALUATION_GRADE_CD').then(_result => {
        this.gradeItems = _result;
      });
      this.$comm.getComboItems('VENDOR_EVALUATION_SCORE_CD').then(_result => {
        this.scoreItems = _result;
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmVendorEvaluationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          _result.data.evaluationPeriod = [_result.data.evaluationStartDate, _result.data.evaluationEndDate]
          this.$_.extend(this.evaluationData, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.getResultItem();
      }
    },
    getResultItem() {
      this.$http.url = this.itemListUrl;
      this.$http.param = {
        plantCd: this.evaluationData.plantCd
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        // vendorEvaluationTypeCd 평가항목 구분코드
        if (_result.data.results && _result.data.results.length > 0) {
          this.$set(this.evaluationData, 'itemResults', _result.data.results)
          this.$set(this.evaluationData, 'itemResultSubTotals', _result.data.subTotals)
        } else {
          this.$set(this.evaluationData, 'itemResults', [])
          this.$set(this.evaluationData, 'itemResultSubTotals', [])
        }
      },);
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    checkColorClass(row) {
      let colorClass = '';
      if (row.evaluationScore >= row.scoreGreat) {
        // 우수
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000001' }).attrVal1;
      } else if (row.evaluationScore < row.scoreGreat && row.evaluationScore >= row.scoreCommonly) {
        // 보통
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000005' }).attrVal1;
      } else if (row.evaluationScore < row.scoreCommonly && row.evaluationScore >= row.scoreInadequate) {
        // 미흡
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000010' }).attrVal1;
      }
      return colorClass;
    },
    saveVendorAssess(flag) {
      if (this.isOld) {
        this.saveUrl = transactionConfig.mdm.cim.vendor.result.update.url;
        this.saveType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.mdm.cim.vendor.result.insert.url;
        this.saveType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          /**
           * 평가항목 총점에 따른 등급 산정
           */
          let saveItemResults = this.evaluationData.itemResults; // this.$_.reject(this.evaluationData.itemResults, { subtotal: true });
          let isValidOver = false;
          this.$_.forEach(saveItemResults, item => {
            if (Number(item.scoring) < Number(item.evaluationScore)) {
              isValidOver = true;
              return false;
            }
          });
          if (isValidOver) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '배점보다 높은 득점을 입력하셨습니다. 다시한번 확인하시기 바랍니다.', 
              type: 'warning', // success / info / warning / error
            });
          } else {
            let message = '저장하시겠습니까?';
            if (flag === 'appr') {
              message = '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?'; 
            }
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: message,
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.evaluationData.regUserId = this.$store.getters.user.userId
                this.evaluationData.chgUserId = this.$store.getters.user.userId
                this.evaluationData.evaluationStartDate = this.evaluationData.evaluationPeriod[0]
                this.evaluationData.evaluationEndDate = this.evaluationData.evaluationPeriod[1]
                
                let _totalscore = 0;
                let _score = 0;
                let _grade = 0;
                this.$_.forEach(saveItemResults, item => {
                  _totalscore += Number(item.scoring);
                  _score += Number(item.evaluationScore);
                  
                  item.regUserId = this.$store.getters.user.userId;
                  item.chgUserId = this.$store.getters.user.userId;
                });
              
                /**
                 * S : 90 <= x <= 100
                 * A : 80 <= x <= 89
                 * B : 70 <= x <= 79
                 * C : 60 <= x <= 69
                 * D :  0 <= x <= 59
                 */
                this.$_.forEach(this.$_.filter(this.gradeItems, { useFlag: 'Y'}), item => {
                  if (_score >= Number(item.attrVal1) && _score <= Number(item.attrVal2)) {
                    _grade = item.code;
                  }
                });
                this.$set(this.evaluationData, 'totalScore', _totalscore)
                this.$set(this.evaluationData, 'score', _score)
                this.$set(this.evaluationData, 'evaluationGradeCd', _grade)
                
                if (flag === 'save') {
                  this.isSave = !this.isSave;
                } else {
                  this.isApproval = !this.isApproval;
                }
                
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      if (this.saveType == 'POST') {
        this.$set(this.popupParam, 'mdmVendorEvaluationId', _result.data)
      }
      this.getDetail();
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.mdmVendorEvaluationId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('deleteAssess');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    customTrClass(props) {
      return props.row.subtotal  ? 'bg-blue-5 vendor-assess-item-sub-total-tr' : '';
    },
  }
};
</script>
<style lang="sass">
.vendor-assess-item-sub-total-tr
  td
    color: white !important
    font-size: 0.95rem
    font-weight: 600
  .text-align-right
    text-align: right
</style>